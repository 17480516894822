<template>
  <div class="edit">
    <h1 class="h1_title">
      <img class="head" src="../../assets/images/company/icon-company-1.png">编辑企业基本信息
    </h1>
    <div class="move-up"></div>
    <div class="completion">
      <h4>信息完善度</h4>
      <div class="bar flex-cen-cen">
        <div 
          v-for="i in barLength" 
          :key="i" 
          :class="{radiusLeft:i==1,radiusRight:i==barLength,blue1:i==max,blue2:i<max||i==max2}"
          :style="{'transition-delay': `${.3+i/12}s`}"
        ></div>
        <p>{{companyInfo.rate}}%</p>
      </div>
    </div>
    <div class="input-row" v-if="companyInfo.account">
      <h4>企业账号</h4>
      <div class="box gray">
        <p style="color:#b3b3b3">{{companyInfo.account}}</p>
      </div>
    </div>
    <div class="input-row">
      <h4>企业规模</h4>
      <div class="box">
        <el-select v-model="rangeId" placeholder="请选择企业规模" class="select-init">
          <el-option
            v-for="item in range"
            :key="item.id"
            :label="item.range"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="input-row">
      <h4>企业名称</h4>
      <div class="box" :class="{error:errorType==1}">
        <input
          class="input drak"
          placeholder='请输入企业名称'
          v-model="companyInfo.companyname"
          @input="errorType=0"
          maxlength="24">
          <!-- <i class="el-icon-circle-close"></i> -->
        <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
      </div>
    </div>
    <div class="input-row">
      <h4>企业头像</h4>
      <div class="box flex-bet-cen" @click="logoShow=true">
        <div class="logo_image flex-cen-cen">
          <img :src="companyInfo.logo_path" v-if="companyInfo.logo_path">
          <i class="el-icon-picture" v-else></i>
        </div>
        <p class="logo_p">{{companyInfo.logo_path?'修改':'添加'}}</p>
      </div>
    </div>
    <div class="input-row">
      <h4>成立年份</h4>
      <el-date-picker 
        type="year" 
        v-model="year"
        format="yyyy"
        :picker-options="pickYearOptions"
        :clearable="false">
      </el-date-picker>
    </div>
    <div class="input-row">
      <h4>所在地区</h4>
      <el-cascader :options="area" @change="areaChange" v-model="cityId"></el-cascader>
    </div>
    <div class="input-row">
      <h4>所属行业</h4>
      <div class="box">
        <el-select v-model="industryId" placeholder="请选择所属行业" class="select-init">
          <el-option
            v-for="item in industry"
            :key="item.id"
            :label="item.industry"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="save">
      <button @click="submit">保 存</button>
    </div>
    
    <!-- 修改企业logo -->
    <el-dialog
      title="修改头像"
      :visible.sync="logoShow"
      :close-on-click-modal="false"
      @close="closeLogo"
      center>
      <p>图片格式必须为：png，bmp，jpeg，jpg；不可大于2M；<br>建议图片尺寸为80px*80px</p>
      <div class="cut_box">
        <canvas ref="canvas" width="80" height="80"></canvas>
        <div class="left flex-cen-cen">
          <img ref="source" :src="logo.src" :style="{width:`${logo.width}px`,height:`${logo.height}px`}">
          <div class="up" v-if="logo.src" :style="{width:`${logo.width}px`,height:`${logo.height}px`}">
            <div class="clip" :style="{width:`${clipsize}px`,height:`${clipsize}px`,left:logo.x+'px',top:logo.y+'px'}"  @mousedown="mousedown" @mousewheel="mousewheel"></div>
          </div>
        </div>
        <div class="right">
          <div class="preview">
            <p>预览</p>
            <div class="img">
              <img v-if="logo.src" :src="logo.src" :style="{
                width:`${logo.viewScale*logo.width}px`,
                height:`${logo.viewScale*logo.height}px`,
                transform: `translate(-${logo.viewScale*logo.x}px,-${logo.viewScale*logo.y}px)`
              }">
            </div>
          </div>
          <div class="chooseBtn">
            {{logo.src ? '重新选择' : '选择图片'}}
            <input type="file" accept="image/png,image/bmp,image/jpeg,image/jpg,image/gif" id="imgBtn" @change="changeLogo">
          </div>
        </div>
      </div>
      <div class="bottom-btn" @click="uploadLogo">确定</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data(){
    return {
      range: [],        //规模
      area: [],         //地区
      industry: [],     //行业
      errorType: 0,     //公司名称错误
      errorMsg: '',     //公司名称错误问题提示
      barLength: 10,    //完成进度格子数
      max: 0,           //格子最大填满数
      max2: 0,          //浅格子的位置

      //企业logo
      logoShow: false,  //修改头像框显隐
      logo: {
        image: '',      //要上传的图片
        src: '',
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        size: 250,      //左框的大小
        viewScale: 1,
      },
      clipsize: 0,      //裁剪框的宽高

      companyInfo: {},  //企业信息
      year: '',         //选择的年份
      rangeId: '',      //选择的规模
      industryId: '',   //选择的行业
      cityId: [],       //选择的地区
      pickYearOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e7;
        }
      },
    }
  },
  created(){
    this.$request({url:'/Index/getRange',
      success: (res) => {
        if(res.code==0) this.range = res.data
      },
      error: () => {}
    })
    this.$request({url:'/Index/getRegion',
      success: (res) => {
        if(res.code==0) this.area = res.data
      },
      error: () => {}
    })
    this.$request({url:'/Index/getIndustry',
      success: (res) => {
        if(res.code==0) this.industry = res.data
      },
      error: () => {}
    })
    this.getInfo()
  },
  methods:{
    areaChange(e){
      this.cityId = e
    },
    getInfo(){
      this.$request({
        method: 'post',
        url: '/Company/getBaseInfo',
        success: (data) => {
          if (data.code == 0) {
            let info =  data.data.company_info
            this.companyInfo = info
            //完成度
            this.max = Math.ceil(info.rate/10)
            this.max2 = Math.floor(info.rate/10)
            //年
            this.year = new Date(info.company_years.toString())
            //地区
            this.cityId = [info.province_id, info.city_id]
            if(info.area_id) this.cityId.push(info.area_id)
            //规模
            this.rangeId = info.range_id || ''
            //行业
            this.industryId = info.industry_id || ''
          } else if (data.code == 101) {
            this.$router.replace({path:'/login',query:{back:this.$route.path}})
            localStorage.clear();
          } else if (data.code == 102) {
            this.$router.replace({path: '/userCenter'})
          } else {
            this.$message({duration: 2000,message: data.msg,type:'error'})
          }
        },
      })
    },
    submit(){
      if (!this.companyInfo.companyname) {
        this.errorType = 1
        this.errorMsg = '企业名称不能为空'
        return
      }
      this.$request({
        method: 'post',
        url: '/Company/update',
        data: {
          logo_path: this.companyInfo.logo_path,
          companyname: this.companyInfo.companyname,
          company_years: this.year.getFullYear() || null,
          industry_id	: this.industryId || null,
          province_id: this.cityId[0] || null,
          city_id: this.cityId[1] || null,
          area_id: this.cityId[2] || null,
          range_id: this.rangeId || null,
        },
        success: (data) => {
          if (data.code == 0) {
            this.getInfo()
            this.$parent.$refs.nav.getTeamInfo()
            this.$message({type:'success',message: '保存成功'})
          } else {
            this.$message({duration: 2000,message: data.msg,type:'error'})
          }
        },
      })
    },
    closeLogo(){
      this.logo = {src:'',width:0,height:0,x:0,y:0,size:250,viewScale:1}
    },
    //选择图片
    changeLogo(ev){
      this.closeLogo()
      let file = ev.target.files[0]
      // this.logo.src = URL.createObjectURL(file);return;//预览
      if (!file) {
        return
      } else if (file.size / 1024 / 1024 > 2) {
        this.$message({duration:2000, message:'文件过大，请重新选择', type:'warning'})
        return
      }
      let img = new Image()
      img.src = this.logo.src = URL.createObjectURL(file)
      img.onload = () => {
        if (img.width < img.height) {
          let scale = this.logo.size / img.height
          this.logo.height =  this.logo.size
          this.logo.width = this.clipsize = this.logo.max = Math.floor(scale * img.width)
        } else {
          let scale = this.logo.size / img.width
          this.logo.width =  this.logo.size
          this.logo.height = this.clipsize = this.logo.max = Math.floor(scale * img.height)
        }
        this.logo.viewScale = 80 / this.clipsize
        this.logo.name = file.name
        this.logo.image = img
      }
      ev.target.value = ''
    },
    mousedown(e) {
      if (this.logo.src) {
        let startx = this.logo.x, starty = this.logo.y;
        document.onmousemove = ev => {
          let moveX = ev.screenX - e.screenX + startx, 
              moveY = ev.screenY - e.screenY + starty;
          this.logo.x = moveX < 0 ? 0 : moveX > (this.logo.width - e.target.offsetWidth) ? this.logo.width - e.target.offsetWidth : moveX
          this.logo.y = moveY < 0 ? 0 : moveY > (this.logo.height - e.target.offsetHeight) ? this.logo.height - e.target.offsetHeight : moveY
        }
        document.onmouseup = () => {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    },
    mousewheel(e) {
      let res = this.clipsize + e.wheelDelta / 20
      this.clipsize = res > this.logo.max ? this.logo.max : res < 80 ? 80 : res
      this.logo.viewScale = 80 / this.clipsize
      if ((e.target.offsetLeft + this.clipsize) > this.logo.width)  //控制left
        this.logo.x = this.logo.width - this.clipsize
      if ((e.target.offsetTop + this.clipsize) > this.logo.height)  //控制top
        this.logo.y = this.logo.height - this.clipsize
    },
    //上传头像
    uploadLogo(){
      if(this.logo.image){
        let canvas = this.$refs.canvas, 
            ctx = canvas.getContext('2d'),
            logo = this.logo;
        ctx.clearRect(0, 0, 80, 80);
        ctx.drawImage(
          logo.image,
          -logo.viewScale*logo.x, -logo.viewScale*logo.y,
          logo.viewScale*logo.width, logo.viewScale*logo.height
        );
        canvas.toBlob((blob)=>{
          let formData = new FormData()
          let file = new File([blob],logo.name);
          formData.append('image',file)
          this.$request({
            method: 'post',
            url: '/Company/uploadImg',
            data: formData,
            success: (data) => {
              if (data.code == 0) {
                this.logoShow = false
                this.companyInfo.logo_path = data.data.logo_path
              } else {
                this.$message({duration: 2000,message: data.msg,type:'error'})
              }
            },
          })
        })
      } else {
        this.$message({duration:2000,message:'请先选择图片',type:'warning'})
      }
    },
  },
}
</script>
<style lang="scss">
.edit{
  overflow: auto !important;
  height: calc(100vh - 50px);
  .completion{
    display: flex;
    color: #868686;
    align-items: center;
    font-size: 14px;
    margin: 25px 0;
    .bar{
      div{
        height: 6px;
        background: #f5f5f5;
        margin-right: 5px;
        width: 50px;
        transition: all .2s linear;
      }
      .blue1{
        background: #cacaef;
      }
      .blue2{
        background: #6b6be5;
      }
      .radiusLeft{
        border-top-left-radius:3px;
        border-bottom-left-radius:3px;
      }
      .radiusRight{
        border-top-right-radius:3px;
        border-bottom-right-radius:3px;
      }
    }
  }
  h4{
    width: 100px;
    margin-right: 15px;
    color: #868686;
    text-align-last: justify;
    font-weight: normal;
    padding-left: 2px;
    position: relative;
    font-size: 14px;
    flex-shrink: 0;
    user-select: none;
  }
  .input-row{
    display: flex;
    align-items: center;
    margin: 25px 0;
    white-space: nowrap;
    max-width: 400px;
    font-size: 14px;
    &:hover{
      .el-icon-circle-close{display: flex}
    }
    .flex-bet-cen{cursor: pointer;}
    .box{
      flex: 1;
      display: flex;
      border: 1px solid #eceef3;
      height: 40px;
      position: relative;
      border-radius: 4px;
      &:hover{
          border-color: #d6dae4;
          .disabled{border-color: #d6dae4;}
          button{border-color: #d6dae4;}
      }
      .input{
        padding: 0 10px;
        flex: 1;
        height: 100%;
        color: #666;
        border-radius: 4px;
      }
      .btn{
        width: 40px;
        height: 38px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: none;
        color: #C0C4CC;
        border-left: 1px solid #eceef3;
      }
      .logo_p{
        font-size: 12px;
        color: #6b6be5;
        margin: 0 10px;
      }
      .logo_image{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 10px;
        font-size: 18px;
        color: #b3b3b3;
        img{width: 100%}
      }
    }
    .select-init{
        padding-left: 0;
        height: 38px;
        flex: 1;
        .el-input--suffix,input{height: 100%;}
        .el-input--suffix{
            .el-input__suffix{right: 8px;}
            input{
                border: none;
                padding-right: 40px;
            }
        }
    }
    .gray{
      padding: 0 10px;
      background: #f8f8f8;
      color: #b5b5b5;
      line-height: 38px;
      &:hover{border-color: #eceef3;}
    }
    .drak{
      color: #333 !important;
    }
    .error{
        border-color: #ff6265;
        &:hover{border-color: #ff6265;}
    }
    .errText{
        position: absolute;
        bottom: -18px;
        font-size: 12px;
        color: #ff6265;
        left: 50%;
        user-select: none;
        transform: translateX(-50%);
    }
    .el-date-editor--year,.el-cascader{
      width: 285px;
      .el-input__inner:focus{border-color: #6b6be5;}
      .is-focus .el-input__inner{border-color: #6b6be5;}
    }
  }
  // .el-icon-circle-close{
  //   align-items: center;
  //   margin-right: 10px;
  //   color: #C0C4CC;
  //   display: none;
  //   cursor: pointer;
  // }
  .save{
    width: 400px;
    margin: 30px 0;
    button{
      width: 100px;
      line-height: 32px;
      background: #6b6be5;
      border-radius: 4px;
      margin: 0 auto;
      display: block;
      color: #fff;
      cursor: pointer;
    }
  }
  .el-dialog__wrapper .el-dialog{
    width: 500px;
    margin-top: 10vh !important;
  }
}
.cut_box{
  display: flex;
  margin-top: 20px;
  user-select: none;
  canvas{
    // position: fixed;left: 10px;top: 10px;
    display: none; opacity: 0;
  }
  .left{
    width: 250px;
    height: 250px;
    background: #eceef3;
    position: relative;
    flex-shrink: 0;
    outline: 1px solid #eceef3;
    overflow: hidden;
    & *{transition: all 0s;}
    img{
      background: #f6f6f6;
    }
    .up{
      position: absolute;
      .clip{
        position: absolute;
        border: 1px dashed #6b6be5;
        cursor: move;
        box-shadow: 0 0 0 300px rgba(0,0,0,.4);
        transition: width .1s, height .1s;
      }
    }
  }
  .right{
    flex: 1;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    .preview{
      border: 1px solid #eceef3;
      flex: 1;
      p{
        font-size: 14px;
        line-height: 50px;
        text-align: center;
      }
      .img{
        width: 80px;
        height: 80px;
        display: block;
        margin: 20px auto 0;
        border-radius: 50%;
        background: #eceef3;
        overflow: hidden;
        border: 1px solid #eceef3;
        img{
          width: 100%;
          transition: width .1s, height .1s;
        }
      }
    }
    .chooseBtn{
      background: #6b6be5;
      border-radius: 4px;
      line-height: 32px;
      height: 32px;
      text-align: center;
      color: #fff;
      margin-top: 20px;
      position: relative;
      input{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: 1000px) {
  .edit .completion .bar div{
    width: 40px;
  }
}

.el-date-picker .el-year-table{
  .current a{
    color: #6b6be5 !important;
    font-weight: bold !important;
  }
  .today a{
    color: #606266;
    font-weight: normal;
  }
}
.el-cascader__dropdown{
  .in-active-path{
    color: #6b6be5;
  }
  .is-active{color: #6b6be5;}
}
</style>